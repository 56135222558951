import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default ({ article }) => (
  
  <div className="classes_preview">
    <Img className="classes_preview_img" alt="" fluid={article.heroImage.fluid} />
    <h3>
      <Link to={`/onlineclasses/${article.databaseUuid}`}>{article.eventName}</Link>
    </h3>
    <small>{article.eventDateTime}</small>
    <p>Zoom Meeting</p>
    <p>Price - FREE</p>
    <a href={`/onlineclasses/${article.databaseUuid}`}>
        <div>
            <button className="book_class_button" type="button">Book Now</button>
        </div>
    </a>
  </div>
)
