import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from "../components/Layout"
import ClassesDetailPreview from '../components/class_detail_preview'
import logo from "../assets/images/base_logo.png"

class TimetableIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title') 
    
    var all_classes = this.props.data.allContentfulOnlineEvent.edges;
    var pagename = 'timetable'
    
    var classes = []
    
    for (var i = 0; i < all_classes.length; i++) {      
      var id_exists = false;
      for (var j =0; j < classes.length; j++) {
        if (all_classes[i].node.databaseUuid == classes[j].node.databaseUuid) { id_exists = true;}
      }
      if (id_exists == false & classes.length < 8) { classes.push(all_classes[i])}
    }
    
    console.log(classes)

    return (
      <Layout location={this.props.location} >
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <div className='general_header timetablespage_header'style={{color:'#ffffff'}} />          
          <div className="page_wrapper"  style={{minHeight:'50vmin'}}>
            <h1 className="section-headline">Timetable</h1>
            <p>Click on the classes below to boot your mat: </p>                                      
            <hr style={{border: '1px solid #73a9ce'}}/>
            <ul className="classesinfo-list">
                {classes.map(function ({ node }) {
                    return (
                        <li key={node.slug}>
                            <ClassesDetailPreview article={node} />
                        </li>
                        )
                    })}
              </ul>
              <hr style={{border: '1px solid #73a9ce'}}/>
          </div>          
        </div>
      </Layout>
    )
  }
}

export default TimetableIndex

export const pageQuery = graphql`
  query TimetableIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulOnlineEvent(sort: {fields: eventDateTime}) {
      edges {
        node {
          capacity
          description {
            description
          }
          databaseUuid
          duration
          eventDateTime(formatString: "ddd MMMM Do HH:mm")
          eventName
          heroImage {
            fluid {
              base64              
              srcWebp
              srcSetWebp
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          link
          price
          seo
          siteName
          stripeSku
        }
      }
    }  
  }
`
